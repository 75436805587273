import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import GaugeComponent from "react-gauge-component";
import * as echarts from "echarts";

const subArcDetails = {
  heart: {
    ranges: [
      {
        limit: 9,
        color: "#09b520",
        showTick: true,
        tooltip: {
          text: "Low Risk",
        },
      },
      {
        limit: 19,
        color: "#fc5908",
        showTick: true,
        tooltip: {
          text: "Intermediate Risk",
        },
      },
      {
        limit: 100,
        color: "#fc0808",
        showTick: true,
        tooltip: {
          text: "High Risk",
        },
      },
    ],
  },
  diabetes: {
    ranges: [
      {
        limit: 10,
        color: "#09b520",
        showTick: true,
        tooltip: {
          text: "Low Risk",
        },
      },
      {
        limit: 34,
        color: "#fc5908",
        showTick: true,
        tooltip: {
          text: "Moderate Risk",
        },
      },
      {
        limit: 100,
        color: "#fc0808",
        showTick: true,
        tooltip: {
          text: "High Risk",
        },
      },
    ],
  },
};

const ScoreGauge = ({ riskData, riskType }) => {
  const chartRef = useRef();

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);
    let option = {
      series: [
        {
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          center: ["50%", "75%"],
          radius: "90%",
          min: 0,
          max: 100,
          anchor: {
            show: true,
            showAbove: true,
            size: 10,
            itemStyle: {
              color: "#2A7EC5",
            },
          },
          splitNumber: 10,
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 12,
              color: getColours(),
              shadowOffsetX: "0",
              shadowOffsetY: "4",
              shadowBlur: "9.1",
              shadowColor: "rgba(0,0,0, 0.25)",
            },
          },
          pointer: {
            // icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
            length: "80%",
            width: 4,
            // offsetCenter: [0, '8%'],
            itemStyle: {
              color: "#2A7EC5",
            },
          },
          axisTick: {
            show: false,
            length: 8,
            distance: 8,
            lineStyle: {
              color: "auto",
              width: 1.5,
            },
          },
          splitLine: {
            distance: 4,
            length: 6,
            lineStyle: {
              color: "#696662",
              opacity: 0.53,
              width: 2,
            },
          },
          axisLabel: {
            show: true,
            color: "#605E5D",
            fontSize: 10,
            distance: 20,
            rotate: "tangential",
          },

          detail: {
            fontSize: 30,
            offsetCenter: [0, "20%"],
            valueAnimation: true,
            // formatter: function (value) {
            //   return Math.round(value * 100) + '';
            // },
            color: "#2A7EC5",
          },
          data: [
            {
              value: getScore(),
              // name: 'Grade Rating'
            },
          ],
        },
      ],
    };
    chartInstance.setOption(option);

    const handleResize = () => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      chartInstance.dispose();
    };
  }, []);

  const getSubArcsArray = () => {
    let result = [];
    switch (riskType) {
      case "heart":
        result = subArcDetails[riskType].ranges;
        break;
      case "diabetes":
        result = subArcDetails[riskType].ranges;
        break;
      default:
        break;
    }
    return result;
  };

  const getScore = () => {
    let value = "";
    switch (riskType) {
      case "heart":
        value =
          riskData?.score && riskData.score.includes("%")
            ? parseFloat(riskData.score.split("%")[0])
            : parseFloat(riskData.score);
        break;
      case "diabetes":
        value = riskData?.score ? riskData.score : "";
      default:
        break;
    }
    console.log("adad", value);
    return value;
  };

  const oldGauge = () => {
    return (
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          // gradient: true,
          subArcs: getSubArcsArray(),
        }}
        pointer={{
          type: "arrow",
          color: "#000000",
          length: 0.8,
          width: 15,
          //   elastic: true,
        }}
        labels={{
          valueLabel: {
            matchColorWithArc: true,
            maxDecimalDigits: 1,
            style: {
              fontSize: "30px",
              textShadow: "2px 4px 3px rgba(0, 0, 0, 0.1)",
              fontWeight: 600,
            },
          },

          //   valueLabel: { formatTextValue: (value) => value + "\n Low Risk" },
          tickLabels: {
            type: "outer",
            defaultTickValueConfig: {
              //   formatTextValue: (value: any) => value + "ºC",
              style: { fontSize: 10, fill: "#000000" },
            },
            defaultTickLineConfig: {
              color: "#000000",
            },
          },
        }}
        value={getScore()}
        minValue={0}
        maxValue={100}
      />
    );
  };

  const getColours = () => {
    let result = [
      [0.09, "#0F9D58"],
      [0.19, "#fc5908"],
      [1, "#DB4437"],
    ];
    switch (riskType) {
      case "heart":
        result = [
          [0.09, "#0F9D58"],
          [0.19, "#F4B400"],
          [1, "#DB4437"],
        ];
        break;
      case "diabetes":
        result = [
          [0.1, "#0F9D58"],
          [0.34, "#F4B400"],
          [1, "#DB4437"],
        ];
        break;
      default:
        break;
    }
    return result;
  };

  const newGauge = () => {
    let option = {
      series: [
        {
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          center: ["50%", "75%"],
          radius: "90%",
          min: 0,
          max: 100,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 8,
              color: getColours(),
            },
          },
          pointer: {
            icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
            length: "60%",
            width: 4,
            // offsetCenter: [0, '8%'],
            itemStyle: {
              color: "auto",
            },
          },
          axisTick: {
            show: false,
            length: 8,
            distance: 8,
            lineStyle: {
              color: "auto",
              width: 1.5,
            },
          },
          splitLine: {
            distance: 8,
            length: 12,
            lineStyle: {
              color: "auto",
              width: 1.5,
            },
          },
          axisLabel: {
            show: false,
          },

          detail: {
            fontSize: 30,
            textShadowColor: "rgba(0, 0, 0, 0.1)", // Shadow color (black with opacity)
            textShadowBlur: 3, // Blur radius
            textShadowOffsetX: 4, // Horizontal shadow offset
            textShadowOffsetY: 2, // Vertical shadow offset
            offsetCenter: [0, "20%"],
            valueAnimation: true,
            // formatter: function (value) {
            //   return Math.round(value * 100) + '';
            // },
            color: "inherit",
          },
          data: [
            {
              value: getScore(),
              // name: 'Grade Rating'
            },
          ],
        },
      ],
    };
  };

  return (
    <Box sx={{ background: "#EDF9FE" }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={1}
        p={1}
        sx={{ background: "#e9ecef", borderRadius: "8px" }}
      >
        <Typography sx={{ color: "#464646" }}>Your Score:</Typography>
        <Typography sx={{ color: "#000000", fontWeight: "bold" }}>
          {riskData.score} {`(${riskData.label})`}
        </Typography>
      </Stack>
      <Box
        ref={chartRef}
        sx={{
          width: { sm: "400px", xs: "300px" },
          height: "300px",
          marginTop: "-50px",
        }}
      >
        {/* <div style={{ width: "300px", height: "300px" }} /> */}
      </Box>
    </Box>
  );
};

export default ScoreGauge;
