import { Box, Card, Paper, Stack, Typography } from "@mui/material";
import GaugeComponent from "react-gauge-component";
import { useLocation } from "react-router-dom";
import ScoreGauge from "./ScoreGauge";

const RiskScorePage = () => {
  const legendData = {
    heart: [
      {
        range: "0-9",
        signal: "Low Risk",
        color: "#0F9D58",
      },
      {
        range: "10-19",
        signal: "Intermediate Risk",
        color: "#F4B400",
      },

      {
        range: "20-100",
        signal: "High Risk",
        color: "#DB4437",
      },
    ],
    diabetes: [
      {
        range: "0-10",
        signal: "Low Risk",
        color: "#0F9D58",
      },
      {
        range: "11-34",
        signal: "Moderate Risk",
        color: "#F4B400",
      },

      {
        range: "35-100",
        signal: "High Risk",
        color: "#DB4437",
      },
    ],
  };
  const location = useLocation();
  const riskScoreData = location.state ? location.state : null;
  const { riskData, riskType } = riskScoreData;

  const getHeading = () => {
    let text = "";
    switch (riskType) {
      case "heart":
        text = "Your Heart Risk Score";
        break;
      case "diabetes":
        text = "Your Diabetes Risk Score";
        break;
      default:
        text = "Your Risk Score";
        break;
    }
    return text;
  };

  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      //   py={1}
      sx={{ padding: { md: "30px", sm: "20px", xs: "0px" } }}
    >
      <Box
        py={3}
        sx={{
          width: { md: "80%", sm: "100%", xs: "100%" },
          padding: { ms: "40px", sm: "24px", xs: "16px" },
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          background: "#EDF9FE",
          borderRadius: "16px",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: { md: "34px", sm: "20px", xs: "20px" },
          }}
        >
          {getHeading()}
        </Typography>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <ScoreGauge riskData={riskData} riskType={riskType} />
        </Stack>
        <Stack
          sx={{
            background: "#fff",
            borderRadius: "8px",
            border: "1px solid #ced4da",
            flexDirection: { md: "row", sm: "column", xs: "column" },
            alignItems: { ms: "center", sm: "flex-start", xs: "flex-start" },
          }}
        >
          {legendData[riskType].map((each) => (
            <Stack
              flexGrow={1}
              key={each.signal}
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
              p={1}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  background: each.color,
                }}
              ></Box>
              <Typography sx={{ color: "#464646" }}>{each.signal}:</Typography>
              <Typography>{each.range}</Typography>
            </Stack>
          ))}
        </Stack>
        {/* suggestions */}
        {riskData?.explanation ? (
          <Box>
            <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
              Suggestions:
            </Typography>
            <Typography>{riskData.explanation}</Typography>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Stack>
  );
};

export default RiskScorePage;
