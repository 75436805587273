import { Box, CircularProgress, Stack } from "@mui/material";
import HeartRiskForm from "./HeartRiskForm";
import DiabetesRiskForm from "./DiabetesRiskForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const UserDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { riskType, clientId, labNo } = Object.fromEntries(queryParams);
  const [loader, setLoader] = useState(false);

  const getSuitableComponent = () => {
    switch (riskType) {
      case "heart":
        return <HeartRiskForm />;
      case "diabetes":
        return <DiabetesRiskForm />;
      default:
        return <HeartRiskForm />;
    }
  };

  const fetchStoredRiskScore = async () => {
    setLoader(true);
    const apiUrl = `https://6ohh9xcizb.execute-api.ap-south-1.amazonaws.com/prod/?riskType=${riskType}&clientId=${clientId}&labNo=${labNo}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      console.log("sassd", data);
      if (data.isSuccess) {
        if (data.data.heartRisk && riskType === "heart") {
          console.log("go to risk page");
          navigate("/riskScore", {
            state: { riskData: data.data.heartRisk.risk, riskType: "heart" },
          });
        }
        if (data.data.diabetesRisk && riskType === "diabetes") {
          navigate("/riskScore", {
            state: {
              riskData: data.data.diabetesRisk.risk,
              riskType: "diabetes",
            },
          });
        }
      }
    } catch (e) {
      console.log("Error fetching data", e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchStoredRiskScore();
  }, []);

  return (
    <Box>
      {loader ? (
        <Stack
          sx={{
            width: "100%",
            height: "500px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        getSuitableComponent()
      )}
    </Box>
  );
};

export default UserDetails;
